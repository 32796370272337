import { redirect, Navigate } from "@solidjs/router";

export function GET() {
  return redirect("/account/profile");
}

export default function AccountLanding() {
  return <Navigate href={"/account/profile"}/>;
}



// export default function AccountLanding() {
//   return (
//     <div class="border border-green-500">
//       Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis neque praesentium vel architecto nam, accusamus illo accusantium eaque quaerat animi fuga. Ullam culpa magni dolores eveniet eum quaerat doloremque? Labore pariatur cupiditate adipisci ratione dolorum maiores voluptatibus in provident delectus.
//     </div>
//   )
// }